import React from "react";
import PhotoGallery from "../PhotoGallery";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";

const HomeGallery = (news) => {
    const gal =  typeof news.news.gallery === 'undefined' || news.news.gallery.length === 0
        ? [] : news.news.gallery;
    const pics = gal.filter((e) => e.have_video === 0);
    const [index, setIndex] = React.useState(0);
    const pgNext = () => setIndex(index < pics.length - 1 ? index + 1 : 0);
    const pgPrev = () => setIndex(index > 0 ? index - 1 : pics.length - 1);
    const origin = typeof news.news.origin === 'undefined' ? '/' : news.news.origin;


    const toPic = (e) => origin + '/assets/images/news/' + e.image;
    const vids = gal.filter((e) => e.have_video === 1);
    const toVid = (obj, i) => {
        let vid = obj.video_link.split('=');
        vid = vid.length > 1 ? vid[vid.length - 1] : '';
        return <div key={vid + i} className="col-xl-6">
            <Link to={'/video/' + obj.id} className="news-box-link">
                <div className="media-box">
                    <iframe width="180" height="130" src={'https://www.youtube.com/embed/' + vid}
                            title={obj.title} frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""></iframe>
                    <i className="icofont-play-alt-3"></i>
                    <div className="news-content-box">
                        <h3 className="video-title"> {obj.title} </h3>
                    </div>
                </div>
            </Link>
        </div>
    }

    return (<section className="ablum-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-8">
                        <Link className="media-title" to="/picture"><i className="icofont-photobucket"></i> ছবি</Link>
                        {pics.length > 0
                            ? <div className="album-box">
                                { pics.filter((p, i) => i === index).map((p, i) => <div key={'abms' + i} className={'mySlides' + (i === 0 ? ' show' : '')}>
                                    <div className="numbertext">{i + 1} / { pics.length}</div>
                                    <ImagePos src={toPic(p)} alt={p.title}/>
                                    <p>{p.title}</p>
                                </div>)}
                                <section className="caption-container">
                                    <a className="prev" onClick={() => pgPrev()}>❮</a>
                                    <a className="next" onClick={() => pgNext()}>❯</a>
                                </section>
                                <section id="thumbnail-section" className="row">
                                    { pics.map((p, i) => <div onClick={() => setIndex(i)} key={'abts' + i} className="thumbnail col-xl-2 pr-0">
                                        <ImagePos cName={'demo cursor' + (i === index ? ' active' : '')} src={toPic(p)} alt={p.title}/>
                                    </div>)}
                                </section>
                            </div>
                            : <div></div>
                        }
                    </div>
                    <div className="col-xl-4">
                        <Link className="media-title" to="/video"> <i className="icofont-video"></i> ভিডিও </Link>
                        <div className="row">
                            { vids.map((e, i) => toVid(e, i))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeGallery;