import React from "react";
import {Link} from "react-router-dom";
import AdsView from "../../AdsView";

const HomeFuturedLeft = ({news}) => {
    return (<div className="col-lg-3">
        <div className="col-lg-12">
            <AdsView size="300x250" arr={news.adds} origin={news.origin} type={2} />
            <AdsView size="300x250" arr={news.adds} origin={news.origin} type={1} />
            <div id="__koo-trending-embed" ua-type="desktop" ua-lang="en" koo-story-count="7"
                 ua-page="all">
                <div className="kooWid-wrap">
                    <div className="kooWid-head">
                        <h2 className="kooWid-heading">
                            <a target="_blank">নিউজ স্পেশাল</a></h2>

                    </div>
                    <div className="kooWid-body">
                        <ul className="kooWid-list">
                            {news.special.map((e) =>
                                <li key={'spec' + e.id} className="kooWid-item">
                                <div className="kooWid-thumb">
                                    <a href={'/read/' + e.id}
                                       target="_blank" className="kooWid-thumbLnk">
                                        <img src={e.photo} alt=""/>
                                    </a>
                                </div>
                                <div className="kooWid-desc scrolright">
                                    <a href={'/read/' + e.id} className="kooWid-listLnk">
                                        <strong className="kooWid-listStr">{e.title}</strong>
                                    </a>
                                </div>
                            </li>)}
                        </ul>
                    </div>

                </div>
            </div>
            <hr/>
            <AdsView size="300x600" arr={news.adds} origin={news.origin} type={1} />
            <AdsView size="300x600" arr={news.adds} origin={news.origin} type={2} />
            <AdsView size="300x250" arr={news.adds} origin={news.origin} type={1} />
        </div>


    </div>)
}

export default HomeFuturedLeft;