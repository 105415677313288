import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../Loading";
import Meta from "../Meta";
import ImagePos from "../ImagePos";

const NewsByCategory = () => {
    const { cat, sub, opt } = useParams();
    const [state, setState] = useState({
        next: 0,
        news: [],
        posts: [],
        showMore: true
    });
    const APIENDPOINT = (document.location.hostname === 'localhost'
        ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
    const handleShowMorePosts = () => {
        let filter = [cat, sub, opt].filter((e) => typeof e !== 'undefined' && e !== '0');
        filter = filter[filter.length - 1];
        let curPage = state.next + 1;

        axios.get(APIENDPOINT + "cat/" + filter + '/' + curPage)
            .then((res) => {
                if (res.data.status && res.data.result && res.data.result.news && res.data.result.news.length > 0) {
                    setState(state => ({
                        posts: state.posts.concat(res.data.result.news),
                        next: curPage,
                        news: state.news,
                        showMore: res.data.result.news.length == 8
                    }));
                }
            })
    };

    useEffect(() => {
        let filter = [cat, sub, opt].filter((e) => typeof e !== 'undefined' && e !== '0');
        filter = filter[filter.length - 1];
            axios.get(APIENDPOINT + "cat/" + filter + '/0')
                .then((res) => {
                    if (res.data.status && res.data.result && res.data.result.category && res.data.result.category.name) {
                        document.title = res.data.result.category.name || "";

                        setState(state => ({
                            posts: [],
                            next: 0,
                            news: res.data.status ? res.data.result : {},
                            showMore: res.data.result.news.length == 5
                        }));
                        const seo = res.data.result.seo;
                        Meta(seo);
                    }
                })
    }, [cat, sub, opt]);

    return (
        typeof state.news.category !== 'undefined' ?
        <section className="feature-section">
            <div className="container-fluid">
                <h1 className="page-title"><i className="icofont-ui-press"></i> {state.news.category.name}</h1>
                <div className="row">
                    { state.news.news.length > 0 ? <div className="col-xl-8 col-md-8">
                        <Link to={'/read/' + state.news.news[0].id} >
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-xl-12">
                                    <ImagePos src={state.news.news[0].photo} alt={state.news.news[0].title} cName="feature-img"/>
                                </div>
                                <div className="col-xl-12">
                                    <div className="feature-content-box ">
                                        <h3 className="category-news-title">{state.news.news[0].title}</h3>
                                        <div className="common-text" dangerouslySetInnerHTML={{__html: state.news.news[0].description}}></div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div> : <div></div>}

                    <div className="col-xl-4 col-md-4">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    state.news.news.map((n, i) => i > 0 && i < 5
                                        ? <div key={'ctv' + i} className="row feature-list-box border-0">
                                    <div className="col-xl-6 col-lg-6 p-0">
                                        <ImagePos src={n.photo} alt={n.title} cName="feature-list-img category-list-img"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 p-0">
                                        <Link to={'/read/' + n.id}>
                                            <div className="feature-list-content">
                                                <h4 className="short-list-title cat-list-title">{n.title}</h4>
                                                <small className="news-time">{n.updated_at}</small>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                        : <div key={'ctv' + i}></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    {
                        state.posts.length > 0 ? state.posts.map(
                            (n, i) =>
                                <div key={'cen' + i} className="col-12 col-lg-3 col-md-4 d-flex mb-4">
                                    <Link to={'/read/' + n.id} className="news-box-link">
                                        <div className="news-box">
                                            <ImagePos cName="common-img-box" src={n.photo} alt={n.title} />
                                            <div className="news-content-box">
                                                <h3 className="news-title"> {n.title} </h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                        ) : <div></div>

                    }
                </div>
                { state.showMore ? <div className="see-more text-center">
                    <a onClick={handleShowMorePosts} className="btn btn-primary">আরো পড়ুন</a>
                </div> : <div>no News found</div>}

            </div>
        </section>
        : <Loading/>
    )
}
export default NewsByCategory;