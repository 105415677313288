import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "axios";

const PageAuthor = () => {
    const { id } = useParams();
    const [obj, setObj] = React.useState({});
    const APIENDPOINT = (document.location.hostname === 'localhost'
        ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
    useEffect(() => {
        axios.get(APIENDPOINT + "author/" + id)
            .then((res) => {
                setObj(res.data.result);
            })
    }, [id])
    return (<section className="common-content-section">
        <div className="container-fluid">
            <div className="author-box">
                <br />
                { typeof obj !== 'undefined' && typeof obj.author !== 'undefined' && obj.author !== null ? <div className="row">
                    <div className="col-lg-2">
                        <img src={obj.author.image} alt={obj.author.name}/>
                    </div>
                    <div className="col-lg-10">
                        <h5>{obj.author.name}</h5>
                        <p className="common-text"></p>
                    </div>
                </div> : <div></div>}

                <hr />
            </div>


            <div className="row border-bottom">
                { typeof obj !== 'undefined' && typeof obj.news !== 'undefined' ?  obj.news.map((e) => <div key={'an' + e.id} className="col-12 col-lg-3 col-md-6 d-flex mb-3">
                    <a href={'/read/' + e.id} className="news-box-link">
                        <div className="news-box">
                            <img className="common-img-box" src={e.photo} alt={e.title} />
                            <div className="news-content-box">
                                <h3 className="news-title"> {e.title} </h3>
                                <p className="short-dis">{e.short_description}</p>
                            </div>
                        </div>
                    </a>
                </div>) : <div></div>}
            </div>
        </div>
    </section>)
}
export default PageAuthor;