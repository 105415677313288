import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import MainMenu from './components/MainMenu'
import LeftMenu from './components/LeftMenu'
import axios from "axios";

function App({children}) {
  const [isActive, setActive] = useState(false);
  const [data, setData] = React.useState([]);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const navigate = useNavigate();

  const updateMeta = (data) => {
    const toTag = (key, obj) => {
      if (key === '')
        return;
      let link = document.createElement('meta');
      link.setAttribute(key, obj['key']);
      link.content = obj.content;
      document.head.appendChild(link);
    }
    if (typeof data === 'undefined' || typeof data.seo === 'undefined' || typeof data.seo.length === 'undefined')
      return;
    const head = document.head;
    data.seo.map((e) => {
      toTag(
          typeof e.property !== 'undefined' ? 'property' : (typeof e.name !== 'undefined' ? 'name' : ''),
          e
      )
      return e;
    });
  };

  const direct = (data) => {
    navigate('/search/' + data);
  }
  const footerMenus = (data) => {
    const offset = Math.floor(data.menu.length / 3);
    const menuObj = [
      data.menu.slice(0, offset),
      data.menu.slice(offset, offset + offset),
      data.menu.slice(offset + offset, data.menu.length)
    ];
    return menuObj.map((e, i) =>
        <div key={'fmr' + i} className="col-md-3 col-sm-4">
          <div className="footer-widget">
            <div key={'fmin' + i} className={i === 0 ? 'footer-menu footer-menu--1' : (i === 2 ? 'footer-menu no-padding' : 'footer-menu')}>
              <ul>{e.map((l, i2) => <li key={'fmin' + i + l.id}>
                <Link to={'/news/' + l.id}>{l.name}</Link>
              </li>)}</ul>
            </div>
          </div>
        </div>
    )
  }
  useEffect(() => {
    const APIENDPOINT = (document.location.hostname === 'localhost'
        ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
    axios.get(APIENDPOINT + "menu")
        .then((res) => {
          updateMeta(res.data.result);
          let seo = res.data.result.seo.filter((e) => e.data_keys === 'seo.data');
          seo = seo.length > 0 ? seo[0].data_values : false;
          let title = seo && seo.keywords && seo.keywords.length > 0
          ? seo.keywords.join(' ') : 'police   news';
          document.title = title;
          setData(res.data.result)
        })
  }, [])
  return (
      <div className={isActive ? 'page__content js-opened': 'page__content'}>
        <section className="header" id="headr">
          <div className="logo-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-4">
                  <div className="nav-open-btn" onClick={toggleClass} >
                    <div className="nav-open-btn__bar"></div>
                    <div className="nav-open-btn__bar"></div>
                    <div className="nav-open-btn__bar"></div>
                  </div>
                  <div className="humb-menu">
                    <nav className={isActive ? 'nav nav--offcanvas-1 js-opened': 'nav nav--offcanvas-1'}>
                      <div className="nav__close"  onClick={toggleClass}></div>
                      <LeftMenu data={data}/>
                    </nav>
                  </div>
                  <div className="search-field">

                      <input onKeyDown={e => e.key === 'Enter' && direct(e.target.value)} type="text" name="search" placeholder=""/>

                  </div>
                  <div className="logo-box">
                    <ul className="multiple-date">
                      <li>
                        <i className="icofont-ui-calendar"></i>
                      </li>
                      <li>
                        <time className=""> {data.today}</time>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-content-center flex-wrap justify-content-center">
                  {typeof data !== 'undefined' && data.logo !== 'undefined' ? <Link to="/">
                    <img src={data.logo} alt="logo" style={{ height: '100px' }}/>
                  </Link> : <div></div>}
                </div>
                <div className="col-xl-4">
                  <div className="notification">
                    <div className="nf">
                      <div className="icon" id="bell"><i className="icofont-notification"></i></div>
                      <div className="notifications" id="box">
                        <h2>Notifications - <span>2</span></h2>
                        <div className="notifications-item"><img src="https://i.imgur.com/uIgDDDd.jpg"
                                                                 alt="img"/>
                        </div>
                        <div className="notifications-item">
                          <img
                              src="https://img.icons8.com/flat_round/64/000000/vote-badge.png"
                              alt="img"/>
                          <div className="text">
                            <h4>John Silvester</h4>
                            <p>+20 vista badge earned</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="btn border font-weight-bold text-primary" href="/staff">Login</a>
                  </div>
                  <div className="socialbtns text-right">
                    <ul>{
                      typeof data.seo !== 'undefined'
                      ? data.seo.filter((e) => e.data_keys === 'social_icon.element')
                          .map((e) => e.data_values)
                          .map((e, i) => <li key={'hsi' + i}>
                      <a href={e.url} className={e.social_icon.split('/').length === 1 ? 'fa fa-lg ' + e.social_icon : ''}>
                        {e.social_icon.split('/').length > 1 ? <img style={{width: '30px'}} src={e.social_icon} alt={e.title}/> : null}
                      </a></li>)
                      : <li></li>
                    }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-menu" id="subnav">
            <div className="container-fluid">
              <div className="row">
                <nav className="navbar navbar-expand-lg navbar-light pl-0 pr-0">
                  <button className="navbar-toggler" type="button" data-toggle="collapse"
                          data-target="#navbarNavDropdown"
                          aria-controls="navbarNavDropdown" aria-expanded="false"
                          aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <MainMenu data={data.menu}/>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {children}
        <a id="button" className="show"></a>
        <footer className="footer-area footer--light">
          <div className="footer-big">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="footer-widget">
                    <div className="widget-about">
                      <p>প্রধান পৃষ্ঠপোষক : ড. বেনজীর আহমেদ, বিপিএম (বার), ইন্সপেক্টর জেনারেল অব পুলিশ,
                        বাংলাদেশ </p>
                      <p>সম্পাদক ও প্রকাশক : মোঃ কামরুজ্জামান, এআইজি (মাল্টিমিডিয়া অ্যান্ড পাবলিসিটি),
                        বাংলাদেশ
                        পুলিশ, পুলিশ হেডকোয়ার্টার্স, ঢাকা, </p>
                      <p>প্রধান কার্যালয় : ুলিশ হেডকোয়ার্টার্স, ঢাকা, বাংলাদেশ। ফোন : ০১৭৫৪৮৮০৭৮। E-mail :
                        news@bdpolice.com info@bdpolice.com </p>
                    </div>
                  </div>
                </div>
                {
                  typeof data.menu !== 'undefined'
                      ? footerMenus(data)
                      : <div className="col-md-3 col-sm-12"></div>
                }
              </div>
            </div>
          </div>

          <div className="mini-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="copyright-text">
                    <p>© স্বত্ব ২০২১ পুলিশ নিউজ। সম্পাদক ও প্রকাশক : বাংলাদেশ পুলিশ | Designed & Developed by : Wolast Technologies </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
  );
}

export default App;
