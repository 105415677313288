import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams, useNavigate} from "react-router-dom";
import Meta from "./Meta";

const PageVideoList = () => {
    const APIENDPOINT = (document.location.hostname === 'localhost'
        ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
    const navigate = useNavigate();
    const { id = '0', page = '0' } = useParams();
    const [state, setState] = useState({
        next: typeof page === 'undefined' ? 0 : parseInt(page),
        news: [],
        posts: [],
        showMore: true
    });

    const toVid = (obj, size) => {
        let _size = typeof size === 'string'
            ? size.split('x')
            : ['100%', '100%'];
        if (_size.length === 1) {
            _size.push('100%');
        }
        let vid = obj.video_link.split('=');
        vid = vid.length > 1 ? vid[vid.length - 1] : '';
        return (<iframe width={_size[0]} height={_size[1]} src={'https://www.youtube.com/embed/' + vid}
                title={obj.title} frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""></iframe>)
    }

    const goto = (id) => {
        setState(state => ({
            posts: state.posts,
            next: 0,
            news: state.news,
            showMore: false
        }));
        navigate('/video/' + id);
    }

    const handleShowMorePosts = () => {
        axios.get(APIENDPOINT + 'videoList/' +
            (typeof id === 'undefined' ? '0' : id) + '/' +
            state.next)
            .then((res) => {
                if (res.data.status && res.data.result && res.data.result.length > 0) {
                    setState(state => ({
                        posts: state.posts.concat(res.data.result),
                        next: state.next + 1,
                        news: state.news,
                        showMore: res.data.result.length == 8
                    }));
                } else {
                    setState(state => ({
                        posts: state.posts,
                        next: state.next + 1,
                        news: state.news,
                        showMore: false
                    }));
                }
            })
    };

    useEffect(() => {
        axios.get(APIENDPOINT + 'videoList/' + (typeof id === 'undefined' ? '0' : id) + '/' + state.next)
            .then((res) => {
                if (res.data.status && res.data.result) {
                    document.title = res.data.result.length > 0 ? res.data.result[0].title : "";
                    setState(state => ({
                        posts: [],
                        next: 1,
                        news: res.data.status ? res.data.result : [],
                        showMore: res.data.result.length == 7
                    }));
                    const seo = res.data.seo;
                    Meta(seo);
                }
            })
    }, [id, page]);


    return (
        <section className="feature-section">
            <div className="container-fluid">
                <h1 className="page-title"><i className="icofont-ui-press"></i> ভিডিও </h1>
        { state.news.length > 0
            ? <div className="row">
                <div className="col-xl-8">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-12 position-relative vl-page-thumb">
                            { toVid(state.news[0], '100%x500')}
                        </div>
                        <div className="col-xl-12">
                            <div className="main-img-thumb">
                                <h4 className="video-title">{state.news[0].title}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="row">
                        {state.news.length > 1 ? state.news.slice(1, 3).map((e) => <div key={'video' + e.id} className="col-lg-12">
                            <a onClick={() => goto(e.id)}>
                                <div className="image-list-box border-0">
                                    <div className="position-relative vl-page-rt-thumb">
                                        { toVid(e, '100%x165')}
                                        <i className="icofont-play-alt-3"></i>
                                    </div>
                                    <div className="news-content-box">
                                        <h4 className="video-title">{e.title}</h4>
                                    </div>
                                </div>
                            </a>
                        </div>) : <div></div>}
                    </div>
                </div>
            </div>
            : <div></div>
        }
        <hr />
        {state.news.length > 4 ? <div className="row">
            {state.news.slice(3, 7).map((e) => <div key={'vid' + e.id} className="col-lg-3 col-md-3 col-sm-4">
                <a  onClick={() => goto(e.id)} className="news-box-link">
                    <div className="media-box image-list-box">
                        { toVid(e, '100%x165')}
                            <i className="icofont-play-alt-3"></i>
                            <div className="news-content-box">
                                <h3 className="video-title">{e.title}</h3>
                            </div>
                    </div>
                </a>
            </div>)}
        </div> : <div></div>}

        {state.posts.reduce((all,one,i) => {
            const ch = Math.floor(i/4);
            all[ch] = [].concat((all[ch]||[]),one);
            return all
        }, []).map((a, i) => <div key={'exr' + i} className="row">
            {a.map((e) => <div key={'vid' + e.id} className="col-lg-3 col-md-3 col-sm-4">
                <a onClick={() => goto(e.id)} className="news-box-link">
                    <div className="media-box image-list-box">
                        { toVid(e, '100%x165')}
                        <i className="icofont-play-alt-3"></i>
                        <div className="news-content-box">
                            <h3 className="video-title">{e.title}</h3>
                        </div>
                    </div>
                </a>
            </div>)}
        </div>)}

        {state.showMore ? <div className="see-more text-center">
            <a onClick={handleShowMorePosts} className="btn btn-primary">আরো পড়ুন</a>
        </div> : <div></div>}

            </div>
        </section>
    )
}

export default PageVideoList;