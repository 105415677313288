import {Link} from "react-router-dom";
import React from "react";

const AdsView = ({arr, size, type, origin}) => {
    type = typeof type === 'undefined' ? 1 : type;
    origin = typeof origin === 'undefined' ? '' : origin;
    const ad = typeof arr !== 'undefined' && arr.filter((e) => e.resolution === size && e.type === type);
    return (
        ad.length > 0
        ? (
            ['980x120', '980x90', '728x90'].indexOf(ad[0].resolution) !== -1
            ? <React.Fragment><div className="container-fluid text-center pt-3 pb-3">
                <Link to={ad[0].redirect_url}>
                    <img src={origin + '/assets/images/advertisement/' + ad[0].ad_image} alt="" className="t-ad"/>
                </Link>
                </div><hr/></React.Fragment>
            : (
                ad[0].type === 2
                ? <div className="igp-video toprightvideo" dangerouslySetInnerHTML={{__html: ad[0].script}}></div>
                : <React.Fragment><Link to={ad[0].redirect_url} className="addBanner">
                    <img className="w-100" src={origin + '/assets/images/advertisement/' + ad[0].ad_image} alt=""/>
                    </Link><hr/></React.Fragment>
            )
        )
        : <div></div>
    )
}

export default AdsView;