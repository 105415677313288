import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NewsByCategory from "./components/NewsByCategory/NewsByCategory";
import PageHome from "./components/PageHome";
import NewsView from "./components/news/NewsView";
import PageSearch from "./components/PageSearch";
import PageAuthor from "./components/PageAuthor";
import PageImageList from "./components/PageImageList";
import PageVideoList from "./components/PageVideoList";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
            <Router>
                <App>
                    <Routes>
                        <Route exact path="/" element={<PageHome/>}/>
                        <Route path="/news/:cat" element={<NewsByCategory />}/>
                        <Route path="/news/:cat/:sub" element={<NewsByCategory />}/>
                        <Route path="/news/:cat/:sub/:opt" element={<NewsByCategory />}/>
                        <Route path="/read/:id" element={<NewsView />}/>
                        <Route path="/search/:query" element={<PageSearch/>}/>
                        <Route path="/author/:id" element={<PageAuthor/>}/>
                        <Route path="/picture" element={<PageImageList />}/>
                        <Route path="/picture/:id" element={<PageImageList />}/>
                        <Route path="/picture/:id/:page" element={<PageImageList />}/>
                        <Route path="/video" element={<PageVideoList />}/>
                        <Route path="/video/:id" element={<PageVideoList />}/>
                        <Route path="/video/:id/:page" element={<PageVideoList />}/>
                    </Routes>
                </App>
            </Router>
    </React.StrictMode>
);

reportWebVitals();
