import {Link} from "react-router-dom";
import React from "react";
import HomeFuturedSimpleList from "./HomeFuturedSimpleList";
import ImagePos from "../../ImagePos";

const HomeFuturedTabsOnly = ({ news, tab1i, tab2i, tab3i, offset, total }) => {
    tab1i = typeof tab1i === 'undefined' ? 0 : tab1i;
    tab2i = typeof tab2i === 'undefined' ? 1 : tab2i;
    tab3i = typeof tab3i === 'undefined' ? 2 : tab3i;
    offset = typeof offset === 'undefined' ? -1 : offset;
    total = typeof total === 'undefined' ? 6 : total;
    const oldNews = news;
    news = {
        news: news.homeFeaturedTabs
    }

    return (<div className="col-lg-12 tab-news">

        <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li className="nav-item">
                <a className="nav-link padedit active" id="home-tab" data-toggle="tab"
                   href="#home" role="tab" aria-controls="home"
                   aria-selected="true">{news.news[tab1i].name}</a>
            </li>
            <li className="nav-item">
                <a className="nav-link padedit" id="profile-tab" data-toggle="tab"
                   href="#profile" role="tab" aria-controls="profile"
                   aria-selected="false">{news.news[tab2i].name}</a>
            </li>
            <li className="nav-item">
                <a className="nav-link padedit" id="contact-tab" data-toggle="tab"
                   href="#contact" role="tab" aria-controls="contact"
                   aria-selected="false">{news.news[tab3i].name}</a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active show" id="home" role="tabpanel"
                 aria-labelledby="home-tab"> {
                     news.news[tab1i].news.map((n, inx) => inx > offset && inx < total
                         ? <Link  key={'home' + n.id} to={'read/' + n.id}>
                <div className="border-bottom-1 pt-3 pb-3 d-flex justify-content-center align-items-center">
                    <div className="col-xl-4 p-0">
                        <ImagePos src={n.photo} alt={n.title} cName="second-feature-list-img" />
                        {
                            //<img src={n.photo} alt="" className="second-feature-list-img "/>
                        }


                    </div>
                    <div className="col-xl-8">
                        <div className="feature-list-content">
                            <h4 className="second-feature-list-title">{n.title}</h4>
                        </div>
                    </div>
                </div>
                         </Link> : <div key={'home' + n.id}></div>) }
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel"
                 aria-labelledby="profile-tab">
                {
                    news.news[tab2i].news.map((n, inx) => inx > offset && inx < total
                        ? <Link  key={'profile' + n.id} to={'read/' + n.id}>
                            <div className="border-bottom-1 pt-3 pb-3 d-flex justify-content-center align-items-center">
                                <div className="col-xl-4 p-0">
                                    <img src={n.photo} alt=""
                                         className="second-feature-list-img "/>
                                </div>
                                <div className="col-xl-8">
                                    <div className="feature-list-content">
                                        <h4 className="second-feature-list-title">{n.title}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link> : <div key={'profile' + n.id}></div>) }
            </div>
            <div className="tab-pane fade" id="contact" role="tabpanel"
                 aria-labelledby="contact-tab">
                {
                    news.news[tab3i].news.map((n, inx) => inx > offset && inx < total
                        ? <Link  key={'contact' + n.id} to={'read/' + n.id}>
                            <div className="border-bottom-1 pt-3 pb-3 d-flex justify-content-center align-items-center">
                                <div className="col-xl-4 p-0">
                                    <img src={n.photo} alt=""
                                         className="second-feature-list-img "/>
                                </div>
                                <div className="col-xl-8">
                                    <div className="feature-list-content">
                                        <h4 className="second-feature-list-title">{n.title}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link> : <div key={'contact' + n.id}></div>) }
            </div>
        </div>
    </div>)
}

export default HomeFuturedTabsOnly;