import React from "react";

const SimpleSelect = ( {obj, onChange} ) => {
    return (
        <div className="form-group" value={obj.value} style={{ visibility: obj.list.length > 0 ? 'visible' : 'hidden' }}>
            <select className="form-control" onChange={e => onChange(e.target.value)}>
                {obj.list.map((e) => <option key={e.label + e.value} value={e.value}>{e.label}</option>)}
            </select>
        </div>
    )
}

export default SimpleSelect;