import {Link} from "react-router-dom";
import React from "react";

const HomeFuturedSimpleList = ({ news, tab, offset, total }) => {
    //tab = typeof tab === 'undefined' ? 0 : tab;
    tab = 0;
    offset = typeof offset === 'undefined' ? 0 : offset;
    total = typeof total === 'undefined' ? 5 : total;
    /*news = {
        news: [news.featured1]
    }*/
    return (<div className="col-lg-12">
        <h2 className="second-title color-green">{news.featured1.name}</h2>
        {
            news.featured1.news.map(
                (n, inx) => <Link key={'hfsl' + n.id} to={'/read/' + n.id}>
                        <div
                            className="border-bottom-1 pt-3 pb-3 d-flex justify-content-center align-items-center">
                            <div className="col-xl-4 p-0">
                                <img src={n.photo} alt=""
                                     className="second-feature-list-img "/>
                            </div>
                            <div className="col-xl-8">
                                <div className="feature-list-content">
                                    <h4 className="second-feature-list-title">{n.title}</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
            )
        }
    </div>)
}

export default HomeFuturedSimpleList;