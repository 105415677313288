import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import PoliceStationsFilterBox from "./PoliceStationsFilterBox";
import ImagePos from "../ImagePos";
const HomeGlobalPolitics = (news) => {

    return (
        typeof news.news.globalPolitics !== 'undefined' && news.news.globalPolitics.news.length > 0
        ? <React.Fragment><section className="global-politics">
            <div className="container-fluid">
                <h2 className="second-title color-green">{news.news.globalPolitics.name}</h2>
                <div className="row">
                    <div className="col-12 col-lg-9 col-md-9">
                        <div className="row">
                            <div className="col-xl-8 border-right-1">
                                <Link to={'/read/' + news.news.globalPolitics.news[0].id}>
                                    <ImagePos src={news.news.globalPolitics.news[0].photo} cName="second-feature-img" alt={news.news.globalPolitics.news[0].title} />
                                    {
                                        //<img src={news.news.globalPolitics.news[0].photo} alt="" className="second-feature-img"/>
                                    }
                                    <h2 className="second-feature-title"> {news.news.globalPolitics.news[0].title}</h2>
                                    <p className="second-feature-content">{news.news.globalPolitics.news[0].short_description}</p>
                                    <small className="news-time">{news.news.globalPolitics.news[0].updated_at}</small>
                                </Link>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    {
                                        news.news.globalPolitics.news.slice(1, 5).map((e, i) => <Link key={'bahsuhedwd' + i} to={'/read/' + e.id}>
                                        <div className="feature-list-box d-flex justify-content-center align-items-center">
                                            <div className="col-xl-8">
                                                <div className="feature-list-content">
                                                    <h4 className="second-feature-list-title">{e.title}</h4>
                                                    <small className="news-time">{e.updated_at}</small>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 p-0">
                                                <ImagePos src={e.photo} alt={e.title} cName="second-feature-list-img "/>
                                            </div>
                                        </div>
                                    </Link>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-6">
                    {typeof news.news.filterBlock !== 'undefined' || typeof news.news.filterBlock !== 'undefined' }
                    <PoliceStationsFilterBox />
                    </div>
                </div>
            </div>
        </section><Divider/></React.Fragment>
        : <div></div>
    )
}
export default HomeGlobalPolitics;