import { Link } from "react-router-dom";
const MainMenu = ({ data }) => {
    function dataToMenu(data2) {
        let items = data2.map((i, inx) =>
            <li key={'mi' + i.id + 'mm' + inx} className={i.children && i.children.length > 0 ? 'dropdown' : null}>
                <Link id={'mi' + i.id + '' + inx} className={i.children && i.children.length > 0 ? 'nav-link nav-item dropdown-toggle' : 'nav-link nav-item'} to={i.link}>{i.name}</Link>
                {
                    i.children && i.children.length > 0 ?
                    <ul className="dropdown-menu" aria-labelledby={'mi' + i.id}
                    style={{paddingTop: '8px', paddingBottom: '8px', marginBottom: '0px' }}
                    >
                        {
                            i.children.length > 0 ? i.children.map((c, cnx) =>
                            <li key={inx + 'wert' + cnx + c.link} className={c.children && c.children.length > 0 ? 'dropdown' : null}>
                                <Link className={c.children && c.children.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item'} to={c.link}>{c.name}</Link>
                                {c.children && c.children.length > 0 ? <ul className="dropdown-menu">{ c.children.map((s, snx) => <li key={inx + '' + cnx + '' + snx}><Link  key={'l' + s.id} className="dropdown-item" to={s.link}>{s.name}</Link></li>) }</ul>  : null}
                            </li>
                            ) : null
                        }
                    </ul>
                    : null
                }
            </li>);
        items.unshift(<li key="mmHome"><Link className="nav-link nav-item" to="/"><i className="icofont-home"></i> <span className="sr-only">(current)</span></Link></li>);
        setTimeout(() => window.proMainMenu(), 1000)
        return items;
    }
    return (
        <ul className="navbar-nav">{
            typeof data !== 'undefined' && data.length > 0
                ? dataToMenu(data)
                : <li>...</li>
        }</ul> 
    )
}

export default MainMenu;