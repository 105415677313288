import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";
const HomeFutureBlock = ({news, num}) => {
    num = typeof num === 'undefined' ? 4 : num;
    setTimeout(() => {
        window.initHomeSlider(num);
    }, 1000);
    return (<React.Fragment><section className={'feature slides' + num}>
        <div className="container-fluid">
            <h2 className="second-title color-green">রকমারী</h2>
            <div className={'swiper mySwiper' + num}>
                <div className="swiper-wrapper">

                        {news.map((e) => <div key={'fcr' + e.id} className="swiper-slide">
                            <Link to={'/read/' + e.news.id}>
                                <div className="feature-box">
                                    <ImagePos src={e.news.photohome} alt={e.news.title} cName="feature-imgg"/>
                                    <p>{e.news.title}</p>
                                    <span>{e.name}</span>
                                </div>
                            </Link>
                        </div>)}

                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    </section><Divider/></React.Fragment>)
}

export default HomeFutureBlock;