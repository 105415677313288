import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";
const HomeTrade = (news) => {
    return ( typeof news.news.trade === 'undefined' ||  news.news.trade.news.length === 0
        ? <div></div>
        : <React.Fragment><section className="trade">
        <div className="container-fluid">
            <h2 className="second-title color-green">{news.news.trade.name}</h2>
            <div className="col-xl-12">
                <div className="row bg-white pb-3">
                    <div className="col-xl-6 border-right-1">
                        {news.news.trade.news.slice(0, 1).map((e) => <Link key={'ht' + e.id} to={'/read/' + e.id}>
                            <ImagePos src={e.photo} alt={e.title} cName="second-feature-img"/>
                            <h2 className="second-feature-title"> {e.title} </h2>
                            <p className="second-feature-content">{e.short_description}</p>
                            <small className="news-time">{e.updated_at}</small>
                        </Link>)}

                    </div>
                    <div className="col-xl-6 pt-4">
                        {news.news.trade.news.slice(1, 3).map((e) =>
                            <React.Fragment key={'ht' + e.id}>
                                <Link to={'/read/' + e.id}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h3 className="feature-news-title2"> {e.title}</h3>
                                            <p className="feature-text3">{e.short_description}</p>
                                            <small className="news-time">{e.updated_at}</small>
                                        </div>
                                        <div className="col-xl-5">
                                            <ImagePos src={e.photo} alt={e.title} cName="world-list-img"/>
                                        </div>
                                    </div>
                                </Link>
                                <hr className="divid"/>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section><Divider/></React.Fragment>)
}

export default HomeTrade;