import React from "react";

const ImagePos = ({src, cName, alt, pos}) => {
    alt = typeof alt === 'undefined' ? '' : alt;
    cName = typeof cName === 'undefined' ? '' : cName;
    let srcs = src.split('?pos=');
    pos = typeof pos === 'undefined'
        ? srcs.length > 1 ? srcs[1].split('x') : []
        : pos.split('x');
    pos = pos.length === 2 ? pos[0] + '% ' + pos[1] + '%' : '50% 50%';
    return (<img className={cName} style={{ objectPosition: pos }} src={src} alt={alt} />)
}

export default ImagePos;