import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import SimpleSelect from "../SimpleSelect";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const PoliceStationsFilterBox = () => {
    const defObj = {
        loading: true,
        list: [],
        division: {},
        district: {},
        policeStation: {}
    };
    const [obj, setObj] = useState(defObj);
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const getDivisions = () => {
        axios.get("https://news.wolast.com/apis/filterBlock/divisions")
            .then((res) => {
                if (res.data.result) {
                    let ob = {
                        loading: false,
                        list: res.data.result,
                        division: res.data.result[0],
                        district: res.data.result[0].children[0],
                        policeStation: res.data.result[0].children[0].children[0]
                    };
                    ob.district.list = res.data.result[0].children;
                    ob.policeStation.list = res.data.result[0].children[0].children;
                    setObj(ob)
                } else {
                    let newDef = defObj;
                    newDef.loading = false;
                    setObj(newDef)
                }
            })
    }
    const onDivisionChange = (val) => {
        let newObj = obj;
        let id = parseInt(val);
        let divisionsVal = newObj.list.filter((e) => e.value === id);
        divisionsVal = divisionsVal.length > 0 ? divisionsVal[0] : { value: 0, label: '', children: []};
        newObj.division = divisionsVal;
        newObj.district = divisionsVal.children.length > 0 ? divisionsVal.children[0] : { value: 0, label: '', children: []};
        newObj.district.list = divisionsVal.children;
        newObj.policeStation = newObj.district.children.length > 0 ? newObj.district.children[0] : { value: 0, label: ''};
        newObj.policeStation.list = newObj.district.children;
        setObj(newObj);
        forceUpdate();
    }
    const onDistrictChange = (val) => {
        let newObj = obj;
        let id = parseInt(val);
        let districtVal = newObj.division.children.filter((e) => e.value === id);
        districtVal = districtVal.length > 0 ? districtVal[0] : { value: 0, label: '', children: []};
        districtVal.list = newObj.division.children;
        newObj.district = districtVal;
        newObj.policeStation = districtVal.children.length > 0 ? districtVal.children[0] : { value: 0, label: '', children: []};
        newObj.policeStation.list = districtVal.children
        setObj(newObj);
        forceUpdate();
    }
    const onPoliceStationChange = (val) => {
        let newObj = obj;
        let id = parseInt(val);
        let selected = newObj.policeStation.list.filter((e) => e.value === id);
        if (selected.length > 0) {
            newObj.policeStation.value = selected.value;
            newObj.policeStation.label = selected.label;
            setObj(newObj);
        }
    }

    const onFilter = () => {
        navigate('/ps/' + obj.policeStation.value);
    }

    useEffect(() => getDivisions(), [])
    return (<React.Fragment>
        {
            obj.loading === false && obj.list.length > 0
                ? <React.Fragment>
                    <h2 className="second-title color-green">আমার এলাকার খবর</h2>
                    <div className="form-group">
                        <select className="form-control" onChange={e => onDivisionChange(e.target.value)} defaultValue={obj.division.value}>
                            {obj.list.map((e) => <option key={'dep' + e.value}  value={e.value}>{e.label}</option>)}
                        </select>
                    </div>
                    <SimpleSelect obj={obj.district} onChange={(e) => onDistrictChange(e)}/>
                    <SimpleSelect obj={obj.policeStation} onChange={(e) => onPoliceStationChange(e)}/>
                    {obj.policeStation.list.length > 0 ? <button onClick={() => onFilter()} className="btn btn-primary btn-block">খুঁজুন</button> : <div></div>}

                </React.Fragment>
                : <div></div>
        }
    </React.Fragment>)
}
export default PoliceStationsFilterBox;
