import React, {useEffect} from "react";
import axios from "axios";
import HomeFuturedRIght from "./home/futured/HomeFuturedRIght";
import HomeFuturedLeft from "./home/futured/HomeFuturedLeft";
import Loading from "./Loading";
import AdsView from "./AdsView";
import HomeBpPolitics from "./home/HomeBpPolitics";
import HomeGlobalPolitics from "./home/HomeGlobalPolitics";
import HomeWorld from "./home/HomeWorld";
import HomeGlobalPoliticsTwo from "./home/HomeGlobalPoliticsTwo";
import HomeOpenion from "./home/HomeOpenion";
import HomeTrade from "./home/HomeTrade";
import HomeFutureBlock from "./home/HomeFutureBlock";

import Divider from "./Divider";
import HomeGallery from "./home/HomeGallery";
import HomeFluideOne from "./home/HomeFluideOne";
import HomeFluideTwo from "./home/HomeFluideTwo";
import Meta from "./Meta";
import HomeFuturedRIghtMobile from "./home/futured/HomeFuturedRIghtMobile";
import {Link} from "react-router-dom";
import ImagePos from "./ImagePos";
import HomeFuturedTabsOnly from "./home/futured/HomeFuturedTabsOnly";
import HomeFuturedSimpleList from "./home/futured/HomeFuturedSimpleList";

const PageHome = () => {
    const [news, setNews] = React.useState([]);

    useEffect(() => {
        axios.get((document.location.hostname === 'localhost'
            ? 'https://news.wolast.com' : document.location.origin) + '/apis/home')
            .then((res) => {
                setNews(res.data.result)
                const seo = res.data.result.seo;
                Meta(seo);
            })
    }, [])
    //let fil = news.adds && news.adds.length > 0 ? news.adds.filter((a) => a.resolution === '980x90') : [];

    return (
        news.length === 0
        ? <Loading/>
        : <React.Fragment>
            <AdsView size="980x90" arr={news.adds} />
            <section className="feature-section topforadd hideOnMobile">
                <div className="container-fluid">
                    <div className="row">
                        <HomeFuturedRIght news={news}/>
                        <HomeFuturedLeft news={news}/>
                    </div>
                </div>
            </section>
            <section className="feature-section topforadd showOnMobile">
                <div className="container-fluid">
                    <div className="row">
                        <HomeFuturedRIghtMobile news={news}/>
                        <AdsView size="300x250" arr={news.adds} origin={news.origin} type={2} />
                        <AdsView size="300x250" arr={news.adds} origin={news.origin} type={1} />
                        <HomeFuturedTabsOnly key="htlscnd" news={news} tab1i={2} tab2i={0} tab3i={1} total={7} />
                        <hr/>
                        <div id="__koo-trending-embed" ua-type="desktop" ua-lang="en" koo-story-count="7"
                             ua-page="all">
                            <div className="kooWid-wrap">
                                <div className="kooWid-head">
                                    <h2 className="kooWid-heading">নিউজ স্পেশাল</h2>
                                </div>
                                <div className="kooWid-body">
                                    <ul className="kooWid-list">
                                        {news.special.map((e) =>
                                            <li key={'spec' + e.id} className="kooWid-item">
                                                <div className="kooWid-thumb">
                                                    <Link to={'/read/' + e.id}
                                                          target="_blank" className="kooWid-thumbLnk">
                                                        <img src={e.photo} alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="kooWid-desc scrolright">
                                                    <Link to={'/read/' + e.id} className="kooWid-listLnk">
                                                        <strong className="kooWid-listStr">{e.title}</strong>
                                                    </Link>
                                                </div>
                                            </li>)}
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <hr/>
                        <AdsView size="300x600" arr={news.adds} origin={news.origin} type={1} />
                        <AdsView size="300x600" arr={news.adds} origin={news.origin} type={2} />
                        <hr/>
                        <HomeFuturedSimpleList news={news} tab={4} total={5} />
                        <hr/>
                        { typeof news.threeCircles === 'undefined' || news.threeCircles.length === 0
                            ? <div></div>
                            : <div className="row pl-3 pr-3">
                                <div className="col-lg-12">
                                    <h2 className="second-title color-green">আইন ও বিধি</h2>
                                </div>
                                {news.threeCircles.map((e) => <div key={'derqsfdsfsdf' + e.id} className="col-lg-4">
                                    <Link to={'/read/' + e.id}>
                                        <div className="pt-3 pb-3 d-flex justify-content-center align-items-center">
                                            <div className="col-xl-4 p-0">
                                                <ImagePos cName="circle" src={e.photo} alt={e.title}/>
                                            </div>
                                            <div className="col-xl-8">
                                                <div className="feature-list-content">
                                                    <h4 className="news-title">{e.title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>)}
                            </div>
                        }
                        <hr/>
                    </div>
                </div>
            </section>

            <Divider/>
            <HomeBpPolitics news={news} />
            <HomeGlobalPolitics news={news}/>
            <HomeWorld news={news}/>
            <HomeGlobalPoliticsTwo news={news}/>

            <AdsView size="980x120" arr={news.adds} />

            <HomeOpenion news={news}/>
            <HomeTrade news={news}/>
            <HomeFutureBlock news={news.carousel}/>
            <HomeGallery news={news}/>

            <HomeFluideOne news={news}/>
            <HomeFluideTwo news={news}/>
                { /**/ }
        </React.Fragment>
    );
}

export default PageHome;