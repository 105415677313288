import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";
const HomeGlobalPoliticsTwo = (news) => {

    return (
        typeof news.news.globalPoliticsTwo === 'undefined' || news.news.globalPoliticsTwo.news.length === 0
        ? <div></div>
        : <React.Fragment><section className="global-politics">
        <div className="container-fluid">
            <h2 className="second-title color-green">{news.news.globalPoliticsTwo.name}</h2>
            <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="row">
                                {news.news.globalPoliticsTwo.news.slice(0, 3).map((e) => <Link key={'gpt' + e.id} to={'/read/' + e.id}>
                                    <div className="feature-list-box d-flex justify-content-center align-items-center">
                                        <div className="col-xl-8">
                                            <div className="feature-list-content">
                                                <h4 className="enter-ttl"> {e.title}</h4>
                                                <small className="news-time">{e.updated_at}</small>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 p-0">
                                            <ImagePos src={e.photo} alt={e.title} cName="second-feature-list-img "/>
                                        </div>
                                    </div>
                                </Link>)}
                            </div>
                        </div>
                        <div className="col-xl-4 border-right-1">
                            {news.news.globalPoliticsTwo.news.slice(3, 4).map((e) => <Link key={'hgp' + e.id} to={'/read/' + e.id} className="gpMain">
                                <ImagePos src={e.photo} cName="second-feature-img2" alt={e.title}/>
                                <h2 className="second-feature-title"> {e.title} </h2>
                                <p className="second-feature-content">{e.short_description}</p>
                                <small className="news-time">{e.updated_at}</small>
                            </Link>)}
                        </div>
                        <div className="col-xl-4">
                            <div className="row">
                                {news.news.globalPoliticsTwo.news.slice(4, 7).map((e) => <Link key={'hgp2' + e.id} to={'/read/' + e.id}>
                                    <div className="feature-list-box d-flex justify-content-center align-items-center">
                                        <div className="col-xl-8">
                                            <div className="feature-list-content">
                                                <h4 className="enter-ttl"> {e.title}</h4>
                                                <small className="news-time">{e.updated_at}</small>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 p-0">
                                            <ImagePos src={e.photo} alt={e.title} cName="second-feature-list-img"/>
                                        </div>
                                    </div>
                                </Link>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section><Divider/></React.Fragment>)
}

export default HomeGlobalPoliticsTwo;