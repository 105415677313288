import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
const PageSearch = () => {
    const { query } = useParams();
    const [news, setNews] = React.useState([]);
    useEffect(() => {
    axios.get("https://news.wolast.com/apis/search/" + query)
        .then((res) => {
            if (res.data.status && res.data.result) {
                document.title = "Search";
                setNews(res.data.result)
            }
        })
    }, [query])
    return (<section className="common-content-section">
        <div className="container-fluid">
            <h1 className="page-title"><i className="icofont-ui-press"></i> {query} </h1>
            <div className="row border-bottom">
                {news.map((e) => <div key={'ps' + e.id} className="col-12 col-lg-3 col-md-6 d-flex mb-3">
                    <a href={'/read/' + e.id} className="news-box-link">
                        <div className="news-box">
                            <img className="common-img-box" src={e.photo} alt="news-img"/>
                                <div className="news-content-box">
                                    <h3 className="news-title"> {e.title}</h3>
                                    <p className="short-dis">{e.short_description}</p>
                                </div>
                        </div>
                    </a>
                </div>)}
            </div>
        </div>
    </section>)
}
export default PageSearch;