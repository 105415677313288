import React, {useEffect} from "react";
import { FacebookProvider, Comments } from 'react-facebook';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Loading from "../Loading";
import Meta from "../Meta";
import ImagePos from "../ImagePos";

const NewsView = () => {
    const { id } = useParams();
    const [news, setNews] = React.useState([]);

    useEffect(() => {
        let key = 'n' + id;
        if (typeof window.Dbp[key] !== 'undefined') {
            document.title = window.Dbp[key].news.title || "";
            setNews(window.Dbp[key])
        } else {
            const APIENDPOINT = (document.location.hostname === 'localhost'
                ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
            axios.get(APIENDPOINT + "read/" + id)
                .then((res) => {
                    if (res.data.status && res.data.result && res.data.result.news && res.data.result.news.title) {
                        window.Dbp[key] = res.data.result;
                        document.title = window.Dbp[key].news.title || "";
                        const seo = res.data.result.seo;
                        Meta(seo);
                        setNews(res.data.status ? res.data.result : {})
                    }
                })
        }

    }, [id])
    return (
        typeof news.news !== 'undefined' ?
            <section className="common-content-section">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-lg-8">
                            <div className="mt-3">
                                <div className="reporter-info">
                                    <Link to="/news/" className="news-cat-name">{news.category.name}</Link>
                                    <b className="d-block" style={{ color: '#4692CB' }}>{news.news.sub_title}</b>
                                    <h2 className="common-page-title">{news.news.title}</h2>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <ul className="list-inline mb-2">
                                                {typeof news.news.user.id !== 'undefined' ? <li className="posted-by">
                                                    <Link to={'/author/' + news.news.user.id}>
                                                        {news.news.user.image !== ''
                                                            ? <img className="author-img" alt={news.news.user.name} src={news.news.user.image}/>
                                                            : <span></span>}
                                                        {news.news.user.name}</Link>
                                                </li> : <li></li>}
                                                <li className="publish-date"><i className="fa fa-clock-o"></i> { news.news.updated_at }
                                                </li>
                                                <li>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ImagePos src={news.news.photo} alt={news.news.image_caption} cName="common-page-img w-100" />
                                <small className="d-inline-block pt-3">{news.news.image_caption}</small>
                                <small className="d-inline-block pt-3 pl-3">{news.news.image_source}</small>
                                <hr />
                                <div className="common-text" dangerouslySetInnerHTML={{__html: news.news.description}}></div>
                                {
                                    news.news.pdf !== null && news.news.pdf !== ''
                                        ? news.news.pdf.split(',').map((e) => <img key={e} src={e} alt="" className="w-100" />)
                                        : <div></div>
                                }
                                <hr />
                                <h3 className="news-title">{news.news.title}</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mt-3">
                                <h2 className="second-title color-green"> প্রবাসে রাজনীতি </h2>
                                { news.extra && news.extra.length > 0
                                    ? news.extra.map((n, i) => <Link key={n.id} to={'/read/' + n.id}>
                                        <div className="row feature-list-box">
                                            <div className="col-xl-4 col-lg-4 p-0">
                                                <ImagePos src={n.photo} alt={n.title} cName="feature-list-img"/>
                                            </div>
                                            <div className="col-xl-8 col-lg-8 p-0">
                                                <div className="feature-list-content">
                                                    <h4 className="short-list-title">{n.title}</h4>
                                                    <small className="news-time">{n.updated_at}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>)
                                    : <div></div>}
                            </div>
                        </div>
                    </div>
                    <br className="mt-5"/>
                    <div className="container-fluid p-0" style={{ width: '100%', minHeight: '100px'}}>
                        <FacebookProvider appId="702456604398539">
                            <Comments href={'/read/' + id} />
                        </FacebookProvider>
                    </div>
                    <br className="mt-5"/>
                </div>
            </section>
            : <Loading/>
    )
}

export default NewsView;