import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";
const HomeWorld = (news) => {
    return (
        typeof news.news.world === 'undefined' || news.news.world.news.length === 0
        ? <div></div>
        : <React.Fragment><section className="wrld">
        <div className="container-fluid">
            <h2 className="second-title color-green">{news.news.world.name}</h2>
            <div className="col-xl-12">
                <div className="row bg-white pb-3">
                    <div className="col-xl-6 border-right-1">
                        <Link to={'/read/' + news.news.world.news[0].id}>
                            <ImagePos src={news.news.world.news[0].photo} alt={news.news.world.news[0].title} cName="second-feature-img"/>
                            <h2 className="second-feature-title"> {news.news.world.news[0].title} </h2>
                            <p className="second-feature-content">{news.news.world.news[0].short_description}</p>
                            <small className="news-time">{news.news.world.news[0].updated_at}</small>
                        </Link>
                    </div>
                    <div className="col-xl-6 pt-4">
                        {news.news.world.news.slice(1, 4).map((e, i) => <React.Fragment  key={'hw' + e.id}>
                                <Link to={'/read/' + e.id}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h3 className="feature-news-title2"> {e.title}</h3>
                                            <p className="feature-text3">{e.short_description}</p>
                                            <small className="news-time">{e.updated_at}</small>
                                        </div>
                                        <div className="col-xl-5">
                                            <ImagePos src={e.photo} cName="world-list-img "/>
                                        </div>
                                    </div>
                                </Link>
                                <hr className="divid"/>
                            </React.Fragment>)}

                    </div>
                </div>
            </div>
        </div>
    </section><Divider/></React.Fragment>)
}

export default HomeWorld;