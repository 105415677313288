const Meta = ( seo ) => {
    document.querySelector('[property="og:title"]').content = seo.title;
    document.querySelector('[name="twitter:title"]').content = seo.title;
    document.querySelector('[name="description"]').content = seo.description;
    document.querySelector('[property="og:description"]').content = seo.description;
    document.querySelector('[name="twitter:description"]').content = seo.description;
    document.querySelector('[property="og:url"]').content = seo.url;
    document.querySelector('[property="og:site_name"]').content = seo.site;
    document.querySelector('[name="twitter:site"]').content = seo.site;
    document.querySelector('[name="twitter:creator"]').content = seo.site;
    document.querySelector('[property="og:image"]').content = seo.image;
    document.querySelector('[property="og:image:secure_url"]').content = seo.image;
    document.querySelector('[name="twitter:image"]').content = seo.image;
    document.querySelector('[property="og:type"]').content = seo.type;
}
export default Meta;