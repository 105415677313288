import React from "react";
import Divider from "../Divider";
import ImagePos from "../ImagePos";
const HomeOpenion = (news) => {
    return ( typeof news.news.openion === 'undefined' || news.news.openion.length === 0
        ? <div></div>
        : <React.Fragment><section className="openion">
        <div className="container-fluid">
            <div className="col-lg-12">
                <div className="row interview-main-card">
                    <div className="col-xl-4">
                        {typeof news.news.heading !== 'undefined' && typeof news.news.heading.description !== 'undefined'
                            ? <React.Fragment>
                                <h2 className="second-title">মতামত</h2>
                                <div className="article-box">
                                    <h2 className="interview-title"> {news.news.heading.title}</h2>
                                    <div className="interview-feature-content">{news.news.heading.description}</div>
                                    <br/>
                                    <b>লেখা: </b> <small>{news.news.heading.name}</small>
                                </div>
                            </React.Fragment>
                            : <div></div>
                        }

                    </div>
                    <div className="col-xl-8">
                        {news.news.openion.map((e) => <div key={'hop' + e.id} className="row interview-card">
                            <div className="col-xl-2 text-center">
                                <a href={'/read/' + e.id}>
                                    <ImagePos src={e.photo}/>
                                </a>
                            </div>
                            <div className="col-xl-10 ">
                                <div className="op-ttl">
                                    <a className="interview-list " href={'/read/' + e.id}> {e.title} </a>
                                </div>
                                { typeof e.user.id === 'undefined' ? <div></div> : <div className="">
                                    <a href={'/author/' + e.user.id}>{e.user.name}</a>
                                </div>}

                            </div>
                        </div>)}
                    </div>
                </div>

            </div>
        </div>
    </section><Divider/></React.Fragment>)
}

export default HomeOpenion;