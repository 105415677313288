import React from "react";
import Divider from "../Divider";
import {Link} from "react-router-dom";
import ImagePos from "../ImagePos";
const HomeBpPolitics = (news) => {

    return (typeof news.news.bdPolitics !== 'undefined' && news.news.bdPolitics.news.length > 0
        ? <React.Fragment><section className="bd-politics-section">
        <div className="container-fluid">
            <h2 className="second-title color-green">{news.news.bdPolitics.name}</h2>
            <div className="row border-bottom">
                {news.news.bdPolitics.news.map((e) => <div key={'gasyuhde' + e.id} className="col-12 col-lg-3 col-md-6 d-flex">
                    <Link to={'/read/' + e.id}
                       className="news-box-link">
                        <div className="news-box">
                            <ImagePos cName="common-img-box" src={e.photo} alt={e.title}/>
                            {
                                //<img className="common-img-box" src={e.photo} alt="news-img"/>
                            }
                            <div className="news-content-box">
                                <h3 className="news-title"> {e.title}</h3>
                                <p className="short-dis">{e.short_description}</p>
                            </div>
                        </div>
                    </Link>
                </div>)}
            </div>
        </div>
    </section><Divider/></React.Fragment>
        : <div></div>
    )
}
export default HomeBpPolitics;