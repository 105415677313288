import React from "react";
import {Link} from "react-router-dom";
import HomeFuturedTabs from "./HomeFuturedTabs";
import ImagePos from "../../ImagePos";


const HomeFuturedRIghtMobile = ({ news }) => {
    return (<div className="col-lg-9">
        <div className="row">
            <div className="col-lg-8">
                <div className="row">
                    <div className="col-lg-12 mb-5">
                        <Link to={'/read/' + news.featuredMain.id}
                              key="mftmn"
                              className="feature-main">
                            <div className="news-box text-center">
                                <ImagePos src={news.featuredMain.photo} alt={news.featuredMain.title}/>
                                {
                                    //<img className="" src={news.featuredMain.photo} alt="news-img"/>
                                }


                                <div className="bg-white p-3">
                                    <h3 className="second-feature-title">{news.featuredMain.title}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    { news.featuredMain2.map((fm2) => <div key={'fms2sw2' + fm2.id} className="col-lg-6">
                        <Link to={'/read/' + fm2.id} className="news-box-link">
                            <div className="news-box">
                                <ImagePos cName="big-news-img" src={fm2.photo} alt={fm2.title} />
                                {
                                    //<img className="big-news-img" src={fm2.photo} alt="news-img"/>
                                }
                                <div className="news-content-box">
                                    <h3 className="news-title"> {fm2.title} </h3>
                                </div>
                            </div>
                        </Link>
                    </div>)}
                    <div className="col-lg-12 mt-4 mb-4">
                        <div className="row">
                            <div className="col-lg-8">
                                { news.featuredMain3.map((e) => <Link  key={'featuredMain3' + e.id} to={'/read/' + e.id}>
                                    <div
                                        className="small-3-row d-flex justify-content-center align-items-center">
                                        <div className="col-xl-9">
                                            <div className="feature-list-content">
                                                <h4 className="news-title">{e.title}</h4>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 p-0">
                                            <ImagePos src={e.photo} alt={e.title} cName="second-feature-list-img "/>
                                            {
                                                //<img src={e.photo} alt={e.title} className="second-feature-list-img "/>
                                            }
                                        </div>
                                    </div>
                                </Link>)}
                            </div>
                            <div className="col-lg-4">
                                <Link key="mft1stOf4"
                                      to={'/read/' + news.featuredMain4[0].id}>
                                    <div className="feature-box feature-small-box">
                                        <ImagePos src={news.featuredMain4[0].photo} alt={news.featuredMain4[0].title} cName="feature-imgg"/>
                                        {
                                            //<img src={news.featuredMain4[0].photo} alt="" className="feature-imgg"/>
                                        }
                                        <p>{news.featuredMain4[0].title}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    { news.featuredMain4.map((fm4, i) => i > 0 && i < 4 ? <div key={'ffhfghm4fwerds' + fm4.id} className="col-lg-4">
                        <Link to={'/read/' + fm4.id}>
                            <div className="feature-box feature-small-box">
                                <ImagePos src={fm4.photo} alt={fm4.title} cName="feature-imgg"/>
                                {
                                    //<img src={fm4.photo} alt="" className="feature-imgg"/>
                                }
                                <p>{fm4.title}</p>
                            </div>
                        </Link>
                    </div> : <div key={'ffhfghm4fwerds23' + i}></div>)}
                    <div className="col-lg-12 mt-4 mb-4">
                        <div className="row">
                            <div className="col-lg-4">
                                <Link to={'/read/' + news.featuredMain4[4].id}>
                                    <div className="feature-box feature-small-box">
                                        <ImagePos src={news.featuredMain4[4].photo} alt={news.featuredMain4[4].title} cName="feature-imgg"/>
                                        {
                                            //<img src={news.featuredMain4[4].photo} alt="" className="feature-imgg"/>
                                        }
                                        <p>{news.featuredMain4[4].title}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-8">{ news.featuredMainList3.map((fml3) => <Link key={'fml3' + fml3.id} to={'/read/' + fml3.id}>
                                <div
                                    className="small-3-row d-flex justify-content-center align-items-center">
                                    <div className="col-xl-9">
                                        <div className="feature-list-content">
                                            <h4 className="news-title">{fml3.title}</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 p-0">
                                        <ImagePos src={fml3.photo} alt={fml3.title} cName="second-feature-list-img "/>
                                        {
                                            //<img src={fml3.photo} alt="" className="second-feature-list-img "/>
                                        }
                                    </div>
                                </div>
                            </Link>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="col-lg-12 mb-4">
                    <h2 className="second-title color-green nirbachito">{news.featured41.name}</h2>
                    <Link to={'read/' + news.featured41.news[0].id}
                       className="news-box-link">
                        <div className="news-box">
                            <ImagePos cName="big-news-img"
                                alt={news.featured41.news[0].title}
                                      src={news.featured41.news[0].photo}/>
                            {
                                //<img className="big-news-img" src={news.featured41.news[0].photo} alt="news-img"/>
                            }
                                <div className="news-content-box">
                                    <h3 className="news-title pb-2 pt-2">{news.featured41.news[0].title}</h3>
                                </div>
                        </div>
                    </Link>
                </div>

            </div>
        </div>


    </div>)
}

export default HomeFuturedRIghtMobile;