import React from "react";
import ImagePos from "../ImagePos";
const HomeFluideOne = (news) => {
    const list = typeof news.news.fluidOne === 'undefined' ? [] : news.news.fluidOne;
    return (<div className="container-fluid mb-5">
        <div className="row">
            {list.map((e, i) => <div key={'hfo' + i + e.name} className="col-xl-3">
                <h2 className="second-title tri-ttl color-green">{e.name}</h2>
                <div className="row bg-white border-right-1 pt-3 pb-3">
                    <div className="col-xl-12">
                        <a href={'/read/' + e.id}>
                            <ImagePos src={e.photo} alt={e.title} cName="others-news-feature-img"/>
                            <h4 className="others-news-feature-title health-bold ">{e.title}</h4>
                        </a>
                    </div>
                    <div className="col-xl-12">
                        <ul className="others-news-feature-content ">
                            {e.more.map((m) => <li key={'hfo' + i + e.name + m.id}>
                                <a className="d-flex align-items-center health-regular"
                                href={'/read/' + m.id}> {m.title}</a>
                                <small className="news-time">{m.updated_at}</small></li>)}
                        </ul>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
    )
}
export default HomeFluideOne;