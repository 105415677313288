import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Meta from "./Meta";

const PageImageList = () => {
    const APIENDPOINT = (document.location.hostname === 'localhost'
        ? 'https://news.wolast.com' : document.location.origin) + '/apis/';

    const { id, page } = useParams();
    const [state, setState] = useState({
        next: typeof page === 'undefined' ? 0 : parseInt(page),
        news: [],
        posts: [],
        showMore: true
    });

    const handleShowMorePosts = () => {
        axios.get(APIENDPOINT + 'imageList/' +
            (typeof id === 'undefined' ? '0' : id) + '/' +
            state.next)
            .then((res) => {
                if (res.data.status && res.data.result && res.data.result.length > 0) {
                    setState(state => ({
                        posts: state.posts.concat(res.data.result),
                        next: state.next + 1,
                        news: state.news,
                        showMore: res.data.result.length == 8
                    }));
                }
            })
    };

    useEffect(() => {
        //const APIENDPOINT = (document.location.hostname === 'localhost' ? 'https://news.wolast.com' : document.location.origin) + '/apis/';
        axios.get(APIENDPOINT + 'imageList/' + (typeof id === 'undefined' ? '0' : id) + '/' + (typeof page === 'undefined' ? '0' : page))
            .then((res) => {
                if (res.data.status && res.data.result) {
                    document.title = res.data.result.length > 0 ? res.data.result[0].title : "";
                    setState(state => ({
                        posts: [],
                        next: 1,
                        news: res.data.status ? res.data.result : [],
                        showMore: res.data.result.length == 7
                    }));
                    const seo = res.data.seo;
                    Meta(seo);
                }
            })
    }, [id, page]);

    return (
        <section className="feature-section">
            <div className="container-fluid">
                <h1 className="page-title"><i className="icofont-ui-press"></i> ছবি </h1>
                {state.news.length > 0 ? <div className="row">
                    <div className="col-xl-8">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-xl-12 position-relative">
                                <img src={state.news[0].image} alt="" className="image-list-feature-img" />
                                <i className="icofont-image album-icon"></i>
                            </div>
                            <div className="col-xl-12">
                                <div className="main-img-thumb">
                                    <h4 className="video-title">{state.news[0].title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="row">
                            {state.news.length > 1 ? state.news.slice(1, 3).map((e) => <div key={e.image} className="col-lg-12">
                                <Link to={'/picture/' + e.id}>
                                    <div className="image-list-box border-0">
                                        <div className="position-relative">
                                            <img src={e.image} alt={e.title} className="" />
                                            <i className="icofont-image album-icon"></i>
                                        </div>
                                        <div className="news-content-box">
                                            <h4 className="video-title">{e.title}</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>)  : <div></div> }
                        </div>
                    </div>
                </div> : <div></div>}

                <hr />
                {state.news.length > 4 ? <div className="row">
                    {state.news.slice(3, 7).map((e) => <div key={e.image} className="col-lg-3 col-md-3 col-sm-4">
                        <Link to={'/picture/' + e.id}>
                            <div className="image-list-box border-0">
                                <div className="position-relative">
                                    <img src={e.image} alt={e.title} />
                                    <i className="icofont-image album-icon"></i>
                                </div>
                                <div className="news-content-box">
                                    <h4 className="video-title">{e.title}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>)}
                </div> : <div></div>}

                {state.posts.reduce((all,one,i) => {
                    const ch = Math.floor(i/4);
                    all[ch] = [].concat((all[ch]||[]),one);
                    return all
                }, []).map((a, i) => <div key={'ex' + i} className="row">{
                    a.map((e) => <div key={e.image} className="col-lg-3 col-md-3 col-sm-4">
                        <Link to={'/picture/' + e.id}>
                            <div className="image-list-box border-0">
                                <div className="position-relative">
                                    <img src={e.image} alt={e.title} />
                                    <i className="icofont-image album-icon"></i>
                                </div>
                                <div className="news-content-box">
                                    <h4 className="video-title">{e.title}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>)
                }</div>)}

                {state.showMore ? <div className="see-more text-center">
                    <a onClick={handleShowMorePosts} className="btn btn-primary">আরো পড়ুন</a>
                </div> : <div></div>}
            </div>
        </section>
    )
}

export default PageImageList;