import { Link } from "react-router-dom";
import React from "react";
const LeftMenu = ({ data }) => {
    function dataToMenu(data) {
        return data.menu.map((m) => <li key={'lm' + m.id} className="nav__item">
            <Link className="nav__link"
               to={m.link}
               title={m.name}> {m.name}</Link>
        </li>)
    }
    return (
        <ul className="nav__list">{
            typeof data.menu === 'object' && data.menu.length > 0
                ? dataToMenu(data) : <li>...</li>
        }</ul>
    )
}

export default LeftMenu;